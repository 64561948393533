import { Middleware } from "redux";
import { RootState } from "../interfaces/LoginInterface";

const PUBLIC_ROUTES = ["/", "/forgot-password"];

// Use 'unknown' instead of empty object type
export const authMiddleware: Middleware<unknown, RootState> =
  (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();

    // Check if we're not on a public route
    if (
      !PUBLIC_ROUTES.some((route) => window.location.pathname.startsWith(route))
    ) {
      if (!state.auth.accessToken) {
        // Only redirect if we're not already on the login page
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      }
    }

    return result;
  };