import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaTachometerAlt,
  FaUsers,
  FaCog,
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaList,
  FaTh,
  FaMoneyBillAlt,
  FaLock,
  FaUnlockAlt, 
  FaUserLock,
  FaDiceD6,
  FaBuffer
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/LoginInterface";
import { LoadingModal } from "../../utils/SpinnerLoader";
import LazyImage from "../../utils/LazyImage";
import { Navbar } from "../../utils/Constant";

interface SidebarProps {
  isCollapsed: boolean;
  isMobile: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  isMobile,
  setIsCollapsed,
}) => {
  const location = useLocation();
  const { isLoggingOut, user } = useSelector((state: RootState) => state.auth);
  const [isUsersDropdownOpen, setUsersDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setItemDropDownOpen] = useState(false);
  const [isBudgetDropdownOpen, setBudgetDropdownOpen] = useState(false);
  const [isAuthDropdownOpen, setAuthDropdownOpen] = useState(false);

  // Close dropdowns when sidebar collapses
  useEffect(() => {
    if (isCollapsed) {
      setUsersDropdownOpen(false);
      setItemDropDownOpen(false);
      setBudgetDropdownOpen(false);
      setAuthDropdownOpen(false);
    }
  }, [isCollapsed]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const collapseDropdown = (navbar: string) => {
    setUsersDropdownOpen(false);
    setItemDropDownOpen(false);
    setBudgetDropdownOpen(false);
    setAuthDropdownOpen(false);
    switch (navbar) {
      case Navbar.USER_LINK:
        setUsersDropdownOpen(!isUsersDropdownOpen);
        break;
      case Navbar.CATEGORY_LINK:
        setItemDropDownOpen(!isCategoryDropdownOpen);
        break;
      case Navbar.BUDGET_LINK:
        setBudgetDropdownOpen(!isBudgetDropdownOpen);
        break;
      case Navbar.AUTHENTICATION_LINK:
        setAuthDropdownOpen(!isAuthDropdownOpen);
        break;
    }
  };

  const mobileCaret = isCollapsed || isMobile ? "hidden" : "ml-2";
  const mobileInline = isCollapsed || isMobile ? "hidden" : "inline";
  const mobileWidth = isCollapsed || isMobile ? "w-100" : "w-full";
  const hamburgerBtn = isCollapsed || isMobile ? "inline" : "hidden";

  const linkClasses = (path: string) =>
    `block py-2.5 px-4 rounded transition duration-200 hover:bg-secondary flex items-center ${
      location.pathname === path ? "bg-secondary" : ""
    } ${mobileWidth}`;

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-primary text-white transition-all duration-300 ease-in-out z-30 ${
        isCollapsed || isMobile ? "w-16" : "w-64"
      }`}
    >
      <LoadingModal isOpen={isLoggingOut || false} />
      <div className="p-4 text-xl font-bold flex justify-between items-center">
        <span
          className={`transition-all duration-300 cursor-pointer ${
            isCollapsed || isMobile ? "hidden" : "block"
          }`}
          onClick={toggleSidebar}
        >
          <LazyImage
            src="https://jasmsolution.sgp1.digitaloceanspaces.com/logo/jms-dashboard-logo"
            alt="JMS Logo"
            className="object-contain h-8"
          />
        </span>
        <button className={`${hamburgerBtn}`} onClick={toggleSidebar}>
          <FaBars size={24} />
        </button>
      </div>

      <nav
        className={`flex-1 flex flex-col ${
          isCollapsed || isMobile ? "items-center" : "items-start"
        } px-4`}
      >
        <Link to="/admin" className={linkClasses("/admin")}>
          <FaTachometerAlt className="mr-2" />
          <span className={`${mobileInline}`}>Dashboard</span>
        </Link>

        <Link to="/admin/items" className={linkClasses("/admin/items")}>
          <FaTh className="mr-2" />
          <span className={`${mobileInline}`}>Items</span>
        </Link>

        <Link to="/admin/categories" className={linkClasses("/admin/categories")}>
          <FaBuffer className="mr-2" />
          <span className={`${mobileInline}`}>Categories</span>
        </Link>

        <Link to="/admin/vendor" className={linkClasses("/admin/vendor")}>
          <FaDiceD6 className="mr-2" />
          <span className={`${mobileInline}`}>Vendors</span>
        </Link>

        <Link to="/admin/expenses" className={linkClasses("/admin/expenses")}>
          <FaList className="mr-2" />
          <span className={`${mobileInline}`}>Expense</span>
        </Link>

        <Link to="/admin/sales" className={linkClasses("/admin/sales")}>
          <FaMoneyBillAlt className="mr-2" />
          <span className={`${mobileInline}`}>Sales</span>
        </Link>

        {(user?.roles.includes("admin") || user?.roles.includes("owner")) && (
          <div className={`${mobileWidth}`}>
            <button
              onClick={() => collapseDropdown(Navbar.AUTHENTICATION_LINK)}
              className="w-full flex items-center justify-between py-2.5 px-4 rounded transition duration-200 hover:bg-secondary"
            >
              <div className="flex items-center">
                <FaLock className="mr-2" />
                <span className={`${mobileInline}`}>Authentication</span>
              </div>
              {isAuthDropdownOpen ? (
                <FaChevronDown className={`${mobileCaret}`} />
              ) : (
                <FaChevronRight className={`${mobileCaret}`} />
              )}
            </button>
            {isAuthDropdownOpen && (
              <div className={`${isCollapsed || isMobile ? "ml-2" : "pl-8"}`}>
                <Link
                  to="/admin/authentication/users"
                  className={linkClasses("/admin/authentication/users")}
                >
                  <FaUsers className="mr-2" />
                  <span className={`${mobileInline}`}>Users</span>
                </Link>
                <Link
                  to="/admin/authentication/roles"
                  className={linkClasses("/admin/authentication/roles")}
                >
                  <FaUserLock className="mr-2" />
                  <span className={`${mobileInline}`}>Roles</span>
                </Link>
                <Link
                  to="/admin/authentication/permissions"
                  className={linkClasses("/admin/authentication/permissions")}
                >
                  <FaUnlockAlt className="mr-2" />
                  <span className={`${mobileInline}`}>Permission</span>
                </Link>
              </div>
            )}
          </div>
        )}

        <Link to="/admin/settings" className={linkClasses("/admin/settings")}>
          <FaCog className="mr-2" />
          <span className={`${mobileInline}`}>Settings</span>
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;