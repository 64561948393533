import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';

interface PusherConfig {
  appKey: string;
  cluster: string;
  channelName: string;
  eventName: string;
  onMessage?: (data: any) => void;
}

export class PusherUtil {
  private static instance: Pusher;
  private static channels: Map<string, any> = new Map();

  // Initialize Pusher instance
  static initialize(appKey: string, cluster: string): void {
    if (!this.instance) {
      this.instance = new Pusher(appKey, {
        cluster: cluster,
        forceTLS: true  // Using forceTLS instead of encrypted
      });
    }
  }

  // Subscribe to a channel
  static subscribe(channelName: string) {
    if (!this.channels.has(channelName)) {
      const channel = this.instance.subscribe(channelName);
      this.channels.set(channelName, channel);
    }
    return this.channels.get(channelName);
  }

  // Unsubscribe from a channel
  static unsubscribe(channelName: string): void {
    if (this.channels.has(channelName)) {
      this.instance.unsubscribe(channelName);
      this.channels.delete(channelName);
    }
  }

  // Bind to an event
  static bind(channelName: string, eventName: string, callback: (data: any) => void) {
    const channel = this.channels.get(channelName);
    if (channel) {
      channel.bind(eventName, callback);
    }
  }

  // Unbind from an event
  static unbind(channelName: string, eventName: string, callback: (data: any) => void) {
    const channel = this.channels.get(channelName);
    if (channel) {
      channel.unbind(eventName, callback);
    }
  }

  // Disconnect Pusher instance
  static disconnect(): void {
    if (this.instance) {
      this.instance.disconnect();
      this.channels.clear();
    }
  }
}

// React Hook for using Pusher
export function usePusher({ appKey, cluster, channelName, eventName, onMessage }: PusherConfig) {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      // Initialize Pusher
      PusherUtil.initialize(appKey, cluster);

      // Subscribe to channel
      PusherUtil.subscribe(channelName);
      setIsConnected(true);

      // Bind to event
      if (onMessage) {
        PusherUtil.bind(channelName, eventName, onMessage);
      }

      // Cleanup
      return () => {
        if (onMessage) {
          PusherUtil.unbind(channelName, eventName, onMessage);
        }
        PusherUtil.unsubscribe(channelName);
      };
    } catch (err) {
      setError(err as Error);
      setIsConnected(false);
    }
  }, [appKey, cluster, channelName, eventName, onMessage]);

  return { isConnected, error };
}