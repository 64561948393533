import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./components/layouts/dashboard/DashboardLayout";
import SpinnerLoader from "./components/utils/SpinnerLoader";
import { HelmetProvider } from "react-helmet-async";

// Lazy loaded components
const Login = lazy(() => import("./components/pages/home/Login"));
const ForgotPassword = lazy(() => import("./components/pages/home/ForgotPassword"));
const Dashboard = lazy(() => import("./components/pages/dashboard/Dashboard"));
const Users = lazy(() => import("./components/pages/dashboard/Users"));
const Settings = lazy(() => import("./components/pages/dashboard/Settings"));
const Departments = lazy(() => import("./components/pages/dashboard/Departments"));
const Item = lazy(() => import("./components/pages/dashboard/Item"));
const Roles = lazy(() => import("./components/pages/dashboard/Roles"));
const Permission = lazy(() => import("./components/pages/dashboard/Permission"));
const Expense = lazy(() => import("./components/pages/dashboard/Expense"));
const Vendor = lazy(() => import("./components/pages/dashboard/Vendor"));
const UserProfile = lazy(() => import("./components/pages/dashboard/UserProfile"));
const SalesIncome = lazy(() => import("./components/pages/dashboard/SalesIncome"));
const HomePage = lazy(() => import("./components/pages/home/HomePage"));
const Category = lazy(() => import("./components/pages/dashboard/Category"));

// Create a wrapper component for admin routes
const AdminRoutes: React.FC = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="settings" element={<Settings />} />
        <Route path="departments" element={<Departments />} />
        <Route path="items" element={<Item />} />
        <Route path="categories" element={<Category />} />
        <Route path="vendor" element={<Vendor />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="sales" element={<SalesIncome />} />
        <Route path="expenses" element={<Expense />} />
        
        {/* Authentication Group */}
        <Route path="authentication">
          <Route path="users" element={<Users />} />
          <Route path="roles" element={<Roles />} />
          <Route path="permissions" element={<Permission />} />
        </Route>
        
        {/* Budget Group */}
        <Route path="budget">
          <Route path="request" element={<Permission />} />
        </Route>

        {/* Catch unmatched admin routes */}
        <Route path="*" element={<Navigate to="/admin" replace />} />
      </Routes>
    </DashboardLayout>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<SpinnerLoader />}>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Admin Routes - All wrapped in DashboardLayout */}
            <Route path="/admin/*" element={<AdminRoutes />} />

            {/* Catch all unmatched routes */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
};

export default App;